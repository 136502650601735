import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMachines() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/machine/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/statistics/data', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
