<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="8"
        md="6"
      >
        <b-card>
          <b-card-header>
            <b-card-title> {{ $t('Statistics') }} </b-card-title>

            <div
              class="font-small-2 mr-25 mb-0 float-right"
              style="width:150px"
            >
              <span>{{ $t('common.month') }}: </span>
              <v-select
                v-model="rangePicker"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dateOptions"
                class="w-100"
                :reduce="val => val.value"
                :clearable="false"
              />
            </div>

          </b-card-header>
        </b-card>
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
      <b-col
        xl="4"
        md="6"
      >
        <statistics-filters
          :company-filter.sync="companyFilter"
          :company-options="companyOptions"

          :object-filter.sync="objectFilter"
          :object-options="objectOptions"

          :machine-filter.sync="machineFilter"
          :machine-options="machineOptions"
        />
        <!--ecommerce-medal :data="data.congratulations" /-->
      </b-col>

    </b-row>

    <b-row class="match-height">
      <b-col md="6">
        <apex-heat-map-chart :data="data.heatMapChart" />
      </b-col>
      <b-col md="6">
        <chartjs-bar-chart :data="data.latestBarChart" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -- >
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <! --/ Bar Chart - Orders -->

          <!-- Chart -- >
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
          <! -- Chart -->
        </b-row>
      </b-col>

      <!-- Revenue Report Card -- >
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <! --/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -- >
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      <! --/ Company Table Card -->

      <!-- Developer Meetup Card -- >
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <! --/ Developer Meetup Card -->

      <!-- Browser States Card -- >
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col>
      <! --/ Browser States Card -->

      <!-- Goal Overview Card -- >
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <! --/ Goal Overview Card -->

      <!-- Transaction Card -- >
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <! --/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle,
  // BCardText,
} from 'bootstrap-vue'

// import { ref, onUnmounted } from '@vue/composition-api'
import { onUnmounted } from '@vue/composition-api'
// import ExtFlatPickr from '@core/components/ext-flat-pickr/ExtFlatPickr.vue'
import vSelect from 'vue-select'
// import { getUserData } from '@/auth/utils'
// import EcommerceMedal from './EcommerceMedal.vue'
import store from '@/store'
import EcommerceStatistics from './EcommerceStatistics.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
// import EcommerceOrderChart from './EcommerceOrderChart.vue'
// import EcommerceProfitChart from './EcommerceProfitChart.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
// import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
// import EcommerceMeetup from './EcommerceMeetup.vue'
// import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
// import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
// import EcommerceTransactions from './EcommerceTransactions.vue'
import ApexHeatMapChart from './ApexHeatMapChart.vue'
import ChartjsBarChart from './ChartjsBarChart.vue'

import StatisticsFilters from './StatisticsFilters.vue'
import ecommerceStoreModule from './ecommerceStoreModule'
import useEcommerceList from './useEcommerceList'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    BCardHeader,
    BCardTitle,
    // BCardText,

    // EcommerceMedal,
    EcommerceStatistics,
    // EcommerceRevenueReport,
    // EcommerceOrderChart,
    // EcommerceProfitChart,
    // EcommerceEarningsChart,
    // EcommerceCompanyTable,
    // EcommerceMeetup,
    // EcommerceBrowserStates,
    // EcommerceGoalOverview,
    // EcommerceTransactions,

    ApexHeatMapChart,
    ChartjsBarChart,

    StatisticsFilters,

    // ExtFlatPickr,
    vSelect,

  },
  setup() {
    const ECOMMERCE_APP_STORE_MODULE_NAME = 'app-ecom'

    // Register module
    if (!store.hasModule(ECOMMERCE_APP_STORE_MODULE_NAME)) store.registerModule(ECOMMERCE_APP_STORE_MODULE_NAME, ecommerceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ECOMMERCE_APP_STORE_MODULE_NAME)) store.unregisterModule(ECOMMERCE_APP_STORE_MODULE_NAME)
    })

    const {
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,

      machineOptions,
      companyOptions,
      objectOptions,

      dateOptions,
      rangePicker,
      data,
      refetchData,

    } = useEcommerceList()

    refetchData()

    return {
      objectFilter,
      companyFilter,
      machineFilter,

      dateOptions,
      rangePicker,

      objectOptions,
      machineOptions,
      companyOptions,
      data,

    }
  },
  created() {
    // data
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
