import { ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import sharedSalesList from './sharedSalesList'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import router from '@/router'

export default function useEcommerceList() {
  // console.log(router.currentRoute.params)

  // Use toast
  const toast = useToast()

  const dateOptions = []
  for (let i = 0; i < 12; i += 1) {
    const start = moment().subtract(i, 'month').startOf('month')
    const stop = moment().subtract(i, 'month').endOf('month')

    dateOptions.push({
      // label: `${start.format('YYYY-MM-DD')}-${stop.format('YYYY-MM-DD')}`,
      label: `${start.format('MM.YYYY')}`,
      value: `${start.format('YYYY-MM-DD')},${stop.format('YYYY-MM-DD')}`,
    })
  }
  const rangePicker = ref(dateOptions[0].value)

  if (router.currentRoute.params.machineFilter) {
    store.commit('appConfig/UPDATE_MACHINE_FILTER', router.currentRoute.params.machineFilter)
    store.commit('appConfig/UPDATE_OBJECT_FILTER', null)
    store.commit('appConfig/UPDATE_COMPANY_FILTER', null)
  }

  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)

  // const data = ref({})
  /* */

  /*
  const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
  }
  */

  const data = ref({
    heatMapChart: {
      chartOptions: {
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: false,
          },
        },
      },
    },
    latestBarChart: {
      /*
      data: {
        labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
        datasets: [
          {
            data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190],
            backgroundColor: chartColors.successColorShade,
            borderColor: 'transparent',
          },
        ],
      },
      */
      /*
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          // backgroundColor: $themeColors.light,
          // titleFontColor: $themeColors.dark,
          // bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                // stepSize: 100,
                min: 0,
                // max: 400,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      */
    },
  })
  /* */

  const refetchData = () => {
    store.dispatch('app-ecom/fetchData', {
      machine: machineFilter.value,
      object: objectFilter.value,
      company: companyFilter.value,
      dateRange: rangePicker.value,
    })
      .then(response => {
        // console.log('RESP', response.data)
        data.value = response.data
        const userData = getUserData()
        data.value.congratulations.name = userData.fullName.split(' ')[0] || userData.username
        // console.log('companyOptions', companyOptions)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching chart data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([machineFilter, companyFilter, objectFilter, rangePicker], () => {
    // console.log('+++++++++++')
    refetchData()
  })

  const machineOptions = ref([])
  store.dispatch('app-ecom/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-ecom/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const objectOptions = ref([])
  store.dispatch('app-ecom/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  return {
    data,

    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,

    companyOptions,
    objectOptions,
    machineOptions,

    dateOptions,
    rangePicker,

  }
}
